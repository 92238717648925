import React from "react";
import Media from "../Media";
import Flag from "../Flag/Flag";
import { IEntry } from "../EntryRow/types";

interface IProps {
  entry: IEntry;
}

const EntryBar: React.FC<IProps> = ({ entry }) => (
  <Media img={<Flag entry={entry} />}>
    <div className="ism-entry-bar__name">
      {entry.player_first_name} {entry.player_last_name}
    </div>
    <div className="ism-entry-bar__team">{entry.name}</div>
  </Media>
);

export default EntryBar;
